
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import EventTypePoint from '@/modules/common/components/ui-kit/event-type-point.vue';
import type Day from '@/modules/common/types/day.type';
import isDayType from '@/modules/common/component-validators/is-date.validator';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: {
        EventTypePoint,
    },
})
export default class EventTypesPoints extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    @Prop({
        type: Number,
        required: true,
        validator: isDayType,
    })
    public day!: Day;

    get hasHolidayEvents() : boolean {
        return this.eventsManagerService.hasDateHolidayEvents(this.date);
    }

    get hasLocalEvents() : boolean {
        return this.eventsManagerService.hasDateOtherEvents(this.date);
    }

    get date(): Date {
        return new Date(this.documentFiltersService.year, this.documentFiltersService.month, this.day);
    }
}
