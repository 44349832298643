
import { Component, Prop } from 'vue-property-decorator';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import formatDate from '@/modules/common/filters/format-date.filter';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import MarketsService, { MarketsServiceS } from '../../markets.service';

interface TableData {
    position?: number;
    page?: number;
    hotelName: string;
    isMyHotel: boolean;
}

@Component({
    filters: {
        Cut(value: string, cutBreakpoint: number) {
            if (value.length > cutBreakpoint) {
                return `${value.substring(0, cutBreakpoint)}...`;
            }

            return value;
        },
    },
    extends: DayTooltipTemplate,
})
export default class MarketsPreviewTooltip extends DayTooltipTemplate {
    @Inject(MarketsServiceS)
    marketsService!: MarketsService;

    @Inject(MarketsCommonServiceS)
    marketsCommonService!: MarketsCommonService;

    @Inject(HotelsServiceS)
    hotelsService!: HotelsService;

    @Inject(DocumentFiltersServiceS)
    documentFilterService!: DocumentFiltersService;

    @Prop({ type: String })
    provider!: string;

    @Prop({ type: Number })
    currentHotelId!: number;

    @Prop({ type: Boolean })
    preventAssignScrollContainer!: boolean;

    @Prop({ type: Boolean })
    showLegend!: boolean;

    @Prop({
        type: Array,
        default: () => [],
    })
    private ignoreHotels!: number[];

    currentHotelRank: number = -1;
    totalHotels: number = 0;

    get cutBreakpoint() {
        return this.showLegend ? 20 : 30;
    }

    get date() {
        const { day } = this;
        const { year, month } = this.documentFilterService;
        const d = new Date(year, month, day);

        const formatter = new Intl
            .DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        return formatter.format(d);
    }

    get document() {
        const { provider, marketsService } = this;
        return marketsService.getDocumentByProvider(provider);
    }

    get tableData(): TableData[] {
        const { day, document, currentHotelId } = this;
        const { marketsCommonService } = this;
        const { hotelsService } = this;
        const palette = this.hotelsService.getHotelsGraphColor();

        if (!document) return [];

        const checkinDate = marketsCommonService
            .checkinDate(day, document);

        if (!checkinDate) return [];

        const toMarketData = (hotelId: string) => {
            const hotelName = hotelsService.getHotelName(+hotelId) || hotelId;
            const isMyHotel = currentHotelId === +hotelId;
            const legendColor = isMyHotel
                ? CURRENT_HOTEL_GRAPH_COLOR
                : palette[hotelId];

            const { position, page } = checkinDate[+hotelId];

            return {
                legendColor,
                hotelName,
                position,
                page,
                isMyHotel,
            };
        };

        const tableData = Object
            .keys(checkinDate)
            .filter(hotelId => !this.ignoreHotels.includes(+hotelId))
            .map(toMarketData)
            .sort((a, b) => a.position - b.position);

        const myHotelIndex = tableData
            .findIndex(d => d.isMyHotel);

        this.currentHotelRank = myHotelIndex + 1;
        this.totalHotels = tableData.length;

        if (myHotelIndex !== -1) {
            let startSlice = myHotelIndex - 3;
            let endSlice = myHotelIndex + 4;
            const additionalSliceBottom = Math.min(0, startSlice);
            const additionalSliceTop = Math.max(0, endSlice - tableData.length);

            startSlice -= additionalSliceTop;
            endSlice += -additionalSliceBottom;

            return tableData
                .slice(Math.max(0, startSlice), Math.min(endSlice, tableData.length));
        }

        return tableData.slice(0, 7);
    }

    get lastUpdatedDate() {
        const { day, document } = this;
        const { marketsCommonService } = this;

        const dateScan = marketsCommonService
            .dayUpdateDate(day, document);

        return formatDate(dateScan ? new Date(dateScan) : null);
    }

    get isHaveData() {
        const { day, document, isOutOfRange } = this;
        const isNoData = this.marketsCommonService.isNoData(day, document);

        return !isNoData && !isOutOfRange;
    }

    get isOutOfRange() {
        return this.marketsCommonService.isOutOfRange(this.document);
    }

    mounted() {
        this.assignScrollContainer();
    }

    private assignScrollContainer() {
        if (this.preventAssignScrollContainer) return;

        const { tooltip } = this.$refs as { tooltip: Vue };

        this.setScrollContainer(tooltip.$el.parentNode as HTMLElement);
    }
}
